@use "../config/" as *;
.no-data {
    @extend %p-4;
    @extend %font-12-pm;
    @extend %neutral-50-bg;
    @extend %flex-c-c;
}
.tab-container-wrap {
    .tab-container-body {
        @extend %mt-3;
    }
    .tab-section {
        @extend %d-none;
    }
    @extend %neutral-50-bg;
    @extend %p-3;
    .para-text {
        line-height: 2.9rem;
        @extend %font-14-pr;
        @extend %neutral-600;
    }
    .highlight-section {
        @extend %mb-3;
    }
    .sub-title,
    .highlight-title {
        @extend %font-16-pm;
        @extend %neutral-1000;
    }
    .tab-head {
        @extend %relative;
        @extend %pb-3;
        &:before {
            content: "";
            height: 0.1rem;
            bottom: 0;
            @extend %neutral-1000-bg-1;
            @extend %w-100;
            @extend %absolute;
        }
    }
    .waf-select-box {
        @extend %neutral-200-bg-2;
        @extend %relative;
        &:before {
            content: "innings";
            top: -2.5rem;
            @extend %capitalize;
            @extend %font-14-pr;
            @extend %absolute;
        }
        .selected-title {
            .title {
                @extend %font-14-pm;
            }
        }
    }
    .select-box-wrap {
        @extend %w-100;
        .select-list {
            @extend %neutral-50-bg;
            .list-item.active {
                @extend %primary-500-bg;
                .list-item-btn {
                    @extend %secondary-1000;
                }
            }
        }
    }
}
.commentary {
    .over-poll-section,
    .rpm-over {
        @extend %d-none;
    }
    .over-info {
        .head {
            @extend %p-2-3;
            @extend %mb-1;
            @extend %font-14-pb;
            @extend %neutral-50;
            @extend %half-radius;
            @extend %secondary-1000-bg;
        }
        .head-wrap {
            @extend %flex-sb-c;
        }
        .over-item {
            @extend %py-3;
            @extend %relative;
            &:before {
                content: "";
                height: 0.1rem;
                bottom: 0;
                @extend %neutral-1000-bg-1;
                @extend %w-100;
                @extend %absolute;
            }
        }
        .body {
            .score {
                border: 0.1rem solid clr(neutral-1000, 1);
                width: max-content;
                height: 3.6rem;
                min-width: 3.6rem;
                @extend %flex-c-c;
                @extend %font-14-pb;
                @extend %half-radius;
                @extend %px-1;
            }
            .four .score {
                @extend %secondary-300-bg;
            }
            .six .score {
                @extend %secondary-800-bg;
                @extend %neutral-50;
            }
            .wicket .score {
                @extend %error-500-bg;
                @extend %neutral-50;
            }
        }
        .over-wrap {
            @extend %gap-3;
            @extend %flex;
        }
        .over-details {
            .over-count {
                @extend %d-none;
            }
        }
    }
    .over-count {
        padding-right: .8rem;
        @extend %relative;
        &::after {
            content: "";
            height: 100%;
            width: .2rem;
            right: var(--space-1);
            @extend %absolute;
            @extend %neutral-1000-bg;
        }
    }
    &-title {
        @extend %font-14-pm;
        @extend %neutral-1000;
        @extend %mb-2;
    }
    &-text {
        line-height: 2.5rem;
        @extend %font-14-pr;
        @extend %neutral-600;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .tab-container-wrap {
        .tab-head {
            display: flex;
            justify-content: flex-end;
        }
        .waf-select-box {
            min-width: 33rem;
            &:before {
                top: unset;
                left: -6.5rem;
            }
        }
    }
    .commentary {
        .commentary-title {
            font-size: 1.6rem;
        }
    }
}