@use "../config" as *;
.waf-cricketscorecard.widget-layout-02 {
    .teams {
        .tab-section {
            @extend %flex;
            .tabs {
                @extend %w-100;
            }
            .tab-name {
                min-height: 4.4rem;
                &.tab-active {
                    .text {
                        @extend %secondary-900;
                    }
                }
                .text {
                    @extend %uppercase;
                    @extend %neutral-900;
                }
            }
            .fullname {
                @extend %d-none;
            }
        }
        .tab-head {
            @extend %pb-0;
            &::before {
                content: unset;
            }
        }
        .player {
            &-img {
                width: 4.5rem;
                height: 4.5rem;
                flex-shrink: 0;
                border: .2rem solid clr(secondary-1000, 2);
                @extend %hidden;
                @extend %mr-2;
                @extend %circle-radius;
                @extend %neutral-50-bg;
            }
            &-name {
                .name {
                    @extend %font-12-pb;
                }
            }
        }
        .table {
            &-title {
                @extend %my-5;
            }
            &-left {
                width: 60%;
            }
            &-right {
                width: 40%;
                @include custom-scroll();
            }
            &-data {
                min-width: 11rem;
                width: 100%;
                flex-shrink: unset;
                .text {
                    @extend %font-12-pm;
                }
                &.label {
                    // display: grid;
                    // grid-template-columns: 4.5rem 1fr;
                    // grid-column-gap: 1rem;
                    @extend %flex-column-fs-fs;
                    @extend %text-left;
                    .player-img {
                        grid-area: 1 / 1 / 3 / 2;
                        @extend %d-none;
                    }
                    // .player-name {
                    //     grid-area: 1 / 2 / 2 / 3;
                    // }
                    // .info {
                    //     grid-area: 2 / 2 / 3 / 3;
                    // }
                    .info {
                        .text {
                            @extend %font-12-pm;
                            @extend %neutral-1000-5;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-cricketscorecard.widget-layout-02 {
        .teams {
            .tab-section {
                .fullname {
                    display: flex;
                }
                .shortname {
                    display: none;
                }
            }
            .player {
                &-name {
                    .name {
                        font-size: 1.4rem;
                    }
                }
            }
            .table {
                &-head {
                    .table-data {
                        .text {
                            font-size: 1.2rem;
                        }
                    }
                }
                &-left {
                    width: 40%;
                }
                &-right {
                    width: 60%;
                }
                &-data {
                    // width: 9rem;
                    .text {
                        font-size: 1.4rem;
                    }
                    &.label {
                        width: 100%;
                        .info {
                            .text {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}