@use '../config/' as *;
.tab-container-wrap {
    &.graph {
        .tab-section {
            display: block;
        }
        .innings-progression-section {
            position: relative;
            .score-label {
                @include position(null, null, var(--space-7), var(--space-2-neg), absolute);
                @extend %font-12-pb;
                @extend %neutral-1000;
            }
            .over-label {
                @extend %d-none;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .tab-container-wrap {
        &.graph {
            .innings-progression-section {
                .score-label {
                    bottom: var(--space-4);
                }
            }
        }
    }
}