@use "../config" as *;
.waf-cricketscorecard {
    .tab-name {
        cursor: pointer;
    }
    .score-data {
        .tabs {
            border-radius: var(--space-1) 0;
            @extend %flex;
            @extend %pl-0;
            .tab-active {
                @extend %primary-500-bg;
                @extend %neutral-1000;
                @extend %font-10-pm;
            }
        }
        .tab-name {
            width: 50%;
            cursor: pointer;
            @extend %py-2;
            @extend %secondary-1000-bg;
            @extend %neutral-50;
            @extend %text-center;
            @extend %font-10-pr;
            &:first-child {
                border-top-left-radius: var(--space-1);
            }
            &:last-child {
                border-top-right-radius: var(--space-1);
            }
            &.ball-status {
                .icon:before {
                    margin-right: .3rem;
                    @include icon(bowler);
                }
            }
            &.fall-wickets {
                .icon:before {
                    margin-right: .3rem;
                    @include icon(wicket-keeper);
                }
            }
        }
        .tabs-container {
            @extend %secondary-200-bg;
            .card-item {
                background: unset;
                @extend %py-4;
            }
        }
        .card-list {
            justify-content: center;
            align-items: center;
        }
        .over-list {
            flex-wrap: wrap;
            @extend %mx-8;
            @extend %gap-2;
            @extend %flex-c-c;
            .four {
                @extend %secondary-300-bg;
            }
            .six {
                @extend %secondary-800-bg;
                @extend %neutral-50;
            }
            .wicket {
                @extend %error-500-bg;
                @extend %neutral-50;
            }
        }
        .over-item {
            border: 0.1rem solid clr(neutral-200);
            flex-wrap: nowrap;
            white-space: nowrap;
            @extend %half-radius;
            @extend %p-1-2;
            @extend %flex;
            @extend %font-12-pb;
            @extend %neutral-50-bg;
        }
        .wicket-status {
            .card-list {
                @extend %flex;
            }
            .card-wrapper {
                @extend %flex-c-c;
            }
            .card-item {
                flex-shrink: 0;
                @extend %w-100;
                @extend %h-100;
                @extend %relative;
                @extend %d-block;
            }
            .text {
                border: 0.1rem solid clr(neutral-200);
                @extend %font-12-pm;
                @extend %neutral-50-bg;
                @extend %half-radius;
                @extend %p-1-2;
            }
        }
        .card-swiper {
            @extend %relative;
        }
        .swiper-button-prev,
        .swiper-button-next {
            top: 50%;
            height: 70%;
            transform: translateY(-50%);
            border-radius: unset;
            @extend %secondary-200-bg;
            @extend %p-2;
            @extend %flex-c-c;
            @extend %absolute;
        }
        .swiper-button-prev {
            border-right: .1rem solid clr(neutral-1000, 2);
            left: 0;
            &::after {
                content: "\e804";
                font: 1.2rem/1 "waf-font-icon";
            }
        }
        .swiper-button-next {
            border-left: .1rem solid clr(neutral-1000, 2);
            right: 0;
            &::after {
                content: "\e805";
                font: 1.2rem/1 "waf-font-icon";
            }
        }
    }
    .team-a {
        .color {
            @extend %secondary-1000-bg;
        }
    }
    .team-b {
        .color {
            @extend %primary-500-bg;
        }
    }
    .tab-legends {
        padding: var(--space-4) var(--space-1);
        @extend %gap-4;
        @extend %flex-fe-c;
        .fullname {
            @extend %d-none;
        }
        .color {
            width: 1.5rem;
            height: 1.5rem;
            aspect-ratio: 1/1;
            @extend %d-inline-block;
            @extend %half-radius;
        }
        .legend-item {
            @extend %flex-n-c;
            &.team-b {
                @include flex-config(flex, row);
            }
        }
    }
    .tab-section {
        --_tab-head-height: calc(6.4rem + env(safe-area-inset-bottom));
        --_tab-index: 2;
        --_footer-tab: 6;
        border-radius: 0;
        @extend %my-0;
        .primary-tabs {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            height: var(--_tab-head-height);
            z-index: var(--_footer-tab);
            @include position(null, 0, 0, 0, fixed);
            @extend %secondary-1100-bg;
            @extend %w-100;
            @extend %hidden;
            .tab {
                &-name {
                    cursor: pointer;
                    @extend %flex-column-c-c;
                    @extend %p-1;
                    &.tab-active {
                        @extend %primary-500-bg;
                        &.tab-name::before {
                            @extend %secondary-1000;
                        }
                        .tab-text {
                            font-weight: 700;
                            @extend %secondary-1000;
                        }
                    }
                    @each $icon, $code in $tabs {
                        &.tab-#{$icon}::before {
                            @include icon($icon, 18);
                            @extend %neutral-50;
                        }
                    }
                }
                &-text {
                    @extend %p-0;
                    @extend %mt-1;
                    @extend %font-11-pr;
                    @extend %capitalize;
                    @extend %neutral-50;
                }
            }
        }
    }
    .secondary-tabs {
        border-bottom: 0.1rem solid clr(neutral-1000, 1);
        .tab-thumbnail {
            @extend %d-none;
        }
        .tab-name {
            @extend %flex-c-c;
            &:not(:last-child) {
                @include separator(50%, y, 0.2, secondary-1000);
            }
            &.tab-active {
                border-bottom: 0.2rem solid clr(secondary-1000);
                font-weight: 700;
                @extend %secondary-1000;
                @extend %transparent-bg;
            }
        }
    }
    .graphs-tab {
        .tab-name {
            @extend %py-2;
        }
    }
    .graph {
        .tab-head {
            @extend %pb-0;
            &::before {
                @extend %d-none;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        .tab-section {
            --_tab-head-height: 4.2rem;
            --_footer-tab: 2;
            .primary-tabs {
                border-radius: .5rem;
                @include position(null, 0, 0, 0, static);
                .tab {
                    &-name {
                        flex-direction: row;
                        &:not(:last-child)::after {
                            height: 100%;
                        }
                        &.tab-active {
                            border-radius: var(--half-radius);
                        }
                        &::before {
                            font-size: 2rem;
                        }
                    }
                    &-text {
                        margin-top: 0;
                        margin-left: var(--space-1);
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .tab-legends {
            .fullname {
                display: block;
            }
            .shortname {
                display: none;
            }
        }
    }
}