@use "../config/" as *;
.waf-cricketscorecard {
    --_title-top: 2rem;
    --_title-height: 0rem;
    --_logo-size: 6rem;
    --_logo-seperator: -1rem;
    --_versus-size: 1.9rem;
    --_versus-font: 1rem;
    --_text-score: 2.2rem;
    --_text-extra: 1rem;
    --_text-status: 1.2rem;
    // padding-top: var(--header-height);
    @extend %relative;
    @extend %mx-3-neg;
    @extend %neutral-50-bg;
    .layout-wrapper {
        max-width: 100%;
    }
    .player-thumbnail {
        @extend %d-none;
    }
    .waf-head {
        position: absolute;
        top: calc(var(--header-height) + var(--_title-top));
        height: var(--_title-height);
        inset-inline: 0;
        @extend %d-none;
        @extend %text-center;
        .title {
            @extend %font-12-pb;
            @extend %neutral-50;
        }
        .head-wrap {
            justify-content: center;
        }
    }
    .card {
        &-section {
            padding-top: var(--header-height);
            @extend %neutral-50;
            @extend %secondary-1000-bg;
        }
        &-head {
            @extend %text-center;
            .match {
                &-info {
                    display: inline-flex;
                    justify-content: center;
                    @extend %font-12-pr;
                    @extend %gap-2;
                    @extend %flex-wrap;
                    .title {
                        font: inherit;
                    }
                }
                &-number {
                    order: -1;
                    font-weight: 700;
                    @extend %relative;
                    &::after {
                        content: ",";
                    }
                    .text,
                    .number {
                        font: inherit;
                    }
                }
                &-time {
                    @extend %w-100;
                    .meta {
                        @extend %uppercase;
                        @extend %font-12-pr;
                    }
                    .date {
                        @include separator(80%, y, 0.1rem, neutral-50, 10);
                        @extend %relative;
                        @extend %pr-1;
                    }
                    .time {
                        @extend %ml-1;
                    }
                }
                &-status {
                    isolation: isolate;
                    @extend %w-100;
                    @extend %relative;
                    @extend %mt-3;
                    &::before {
                        content: "";
                        background: linear-gradient(270deg,
                                transparent 4.49%,
                                clr(neutral-50, 2) 55.01%,
                                transparent 97.54%);
                        height: 0.1rem;
                        left: 0;
                        z-index: var(--z-patterns);
                        @extend %pos-y-center;
                        @extend %w-100;
                    }
                    .status {
                        border-radius: var(--quarter-radius);
                        box-shadow: 0px 2px 2px 0px clr(neutral-1000, 2);
                        width: max-content;
                        @extend %mx-auto;
                        @extend %p-1;
                        @extend %font-12-pb;
                        @extend %neutral-50-bg;
                        @extend %neutral-900;
                        @extend %flex-n-c;
                        @extend %gap-1;
                        &::before {
                            content: "";
                            width: var(--_live-size, 1.1rem);
                            height: var(--_live-size, 1.1rem);
                            filter: brightness(0) invert(1);
                            @include background(null, "svg/live.svg");
                            @extend %d-none;
                        }
                    }
                }
            }
        }
        &-item {
            padding-top: calc(var(--_title-top) + var(--_title-height));
            // @include background(null, "matchcenter/masthead-bg.png", center/cover fixed);
            @extend %secondary-900-bg;
            @extend %hidden;
            &.upcoming {
                .team-data-wrap {
                    @extend %d-none;
                }
                .card-body {
                    padding-bottom: var(--_countdown-height, 5rem);
                }
            }
            &.live {
                .status {
                    color: var(--neutral-50);
                    @extend %success-500-bg;
                    &::before {
                        display: block;
                    }
                }
            }
            &.recent {
                .status {
                    color: var(--neutral-50);
                    @extend %error-500-bg;
                }
            }
            &.super-over {
                .team-score {
                    --_text-score: 1.6rem;
                    @extend %flex-n-c;
                    @extend %gap-1;
                    .run-rate {
                        @extend %d-none;
                    }
                    &:not(:last-child) {
                        border-bottom: 0.1rem solid clr(neutral-50);
                    }
                    &:nth-child(2) {
                        --_text-score: 1.2rem;
                        --_text-extra: 0.8rem;
                    }
                    &:nth-child(3) {
                        --_text-score: 1rem;
                        --_text-extra: 0.6rem;
                    }
                }
            }
        }
        &-body {
            @extend %my-4;
            @extend %relative;
            .team {
                @extend %flex-n-c;
                @extend %gap-4;
                &-a {
                    .team-extra {
                        justify-content: flex-end;
                    }
                }
                &-b {
                    flex-direction: row-reverse;
                    @extend %text-right;
                    .team-img::after {
                        right: auto;
                        left: var(--_logo-seperator);
                    }
                }
                &-wrap {
                    @extend %flex-c-c;
                    @extend %gap-1;
                }
                &-name {
                    @extend %d-none;
                }
                &-img {
                    width: var(--_logo-size);
                    height: var(--_logo-size);
                    filter: drop-shadow(0rem 0rem 2rem clr(neutral-1000));
                    @include separator(100%, y);
                    &::after {
                        right: var(--_logo-seperator);
                        left: auto;
                        background: linear-gradient(to bottom,
                                transparent 0%,
                                clr(neutral-50, 4) 50%,
                                transparent 100%);
                    }
                }
                &-score {
                    min-width: var(--_team-score-width, 8.2rem);
                    .score {
                        font-weight: 700;
                        font-size: var(--_text-score);
                    }
                    .no-score {
                        font-size: 1.4rem;
                    }
                }
                &-extra {
                    opacity: 0.8;
                    font-size: var(--_text-extra);
                    flex-direction: row-reverse;
                    @extend %gap-1;
                    @extend %neutral-50;
                    @extend %flex;
                    .overs,
                    .run-rate {
                        font: inherit;
                    }
                }
                &-data-wrap {
                    display: grid;
                    @extend %relative;
                }
                &-a {
                    .team-data-wrap {
                        place-items: flex-start;
                    }
                }
                &-b {
                    .team-data-wrap {
                        place-items: flex-end;
                    }
                }
                &-divider {
                    flex-shrink: 0;
                    .text-vs {
                        width: var(--_versus-size);
                        height: var(--_versus-size);
                        font-size: var(--_versus-font);
                        @extend %flex-c-c;
                        @extend %circle-radius;
                        @extend %neutral-50-bg-2;
                        @extend %neutral-50;
                    }
                }
                &-won,
                &-current {
                    .team-score:last-child .score {
                        @extend %primary-500;
                    }
                }
            }
        }
        &-footer {
            .team {
                &-status {
                    background: linear-gradient(270deg,
                            transparent 0%,
                            clr(secondary-1000) 22.43%,
                            clr(secondary-1000) 80.56%,
                            transparent 101.97%);
                    font-size: var(--_text-status);
                    text-wrap: balance;
                    @extend %relative;
                    @extend %py-3;
                    @extend %m-4-3;
                    @extend %text-center;
                    @include separator();
                    &::after,
                    &::before {
                        content: "";
                        height: 0.1rem;
                        background-color: clr(neutral-50, 3);
                        position: absolute;
                        left: 50%;
                        translate: -50% 0;
                        @extend %w-100;
                    }
                    &::after {
                        bottom: 0;
                        @include mask(linear-gradient(270deg, transparent 0%, clr(secondary-1000) 50%, transparent 101.97%));
                    }
                    &::before {
                        top: 0;
                        @include mask(linear-gradient(270deg, transparent 0%, clr(secondary-1000) 50%, transparent 101.97%));
                    }
                }
                &-venue {
                    @extend %text-center;
                    @extend %font-12;
                    @extend %m-4-3;
                    .text {
                        font: inherit;
                        &::before {
                            @extend %mr-1;
                            @include icon(venue, 12);
                        }
                    }
                }
            }
            .end-match-footer {
                @extend %text-center;
                @extend %mb-5;
                .text {
                    @extend %font-12-pr;
                }
                .player-name {
                    @extend %font-14-pb;
                    @extend %primary-500;
                    @extend %capitalize;
                }
            }
        }
    }
    .countdown {
        &-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            @extend %w-100;
            @extend %flex-c-c;
            > .icon::before {
                @include icon(stopwatch, 20);
            }
        }
        &-list {
            @extend %flex;
        }
        &-item {
            min-width: 6rem;
            @extend %flex-column-c-c;
            @extend %uppercase;
            &:not(:last-child) {
                @extend %relative;
                @include separator(80%, y);
            }
        }
        &-count {
            @extend %d-block;
            @extend %font-18-pm;
        }
        &-label {
            @extend %d-block;
            @extend %font-12;
        }
    }
    .super-over {
        .score {
            @extend %neutral-50-6;
        }
    }
    .live-match-footer {
        @extend %px-3;
        @extend %py-4;
        @extend %neutral-50-bg;
        color: black;
        .head {
            .text {
                @extend %font-14-pm;
                @extend %mb-2;
            }
        }
        .innings-info {
            flex-wrap: wrap;
            @extend %flex;
            @extend %gap-3;
            @extend %mb-4;
        }
        .player-details {
            @extend %w-100;
        }
        .player-data {
            border: .1rem solid clr(neutral-1000);
            @extend %p-2-3;
            @extend %half-radius;
            @extend %flex-sb-c;
            @extend %w-100;
        }
        .player-info {
            @extend %flex-n-c;
            &.active {
                &:before {
                    @include icon(play, 12);
                    @extend %success-500;
                    @extend %mr-1;
                }
            }
            width: 50%;
            &-content {
                @extend %flex;
            }
            .player-team {
                @extend %d-none;
            }
            &:nth-child(2) {
                justify-content: flex-end;
            }
            .name {
                @extend %font-14-pr;
                @extend %mr-1;
            }
            .player-score {
                @extend %font-16-pb;
                @extend %secondary-1000;
            }
        }
    }
    .footer-wrap {
        --_z-footer: 5;
        padding-bottom: unset;
        z-index: var(--_z-footer);
    }
}
.sticky-score-card {
    .waf-cricketscorecard {
        --_logo-size: 3rem;
        --_z-score-card: 6;
        .card {
            &-body {
                z-index: var(--_z-score-card);
                width: 100%;
                height: var(--sticky-score-board-height);
                @include position(var(--header-height), null, null, 0, fixed);
                @include transition(500ms ease-in-out);
                @extend %flex-c-n;
                @extend %my-0;
                @extend %secondary-1000-bg;
                .team {
                    flex-direction: row-reverse;
                    gap: var(--space-1);
                    @extend %relative;
                    &-a {
                        .shortname {
                            right: calc(var(--_logo-size) + var(--space-1));
                            @extend %absolute;
                            @extend %mt-9-neg;
                        }
                    }
                    &-b {
                        flex-direction: row;
                        .shortname {
                            left: calc(var(--_logo-size) + var(--space-1));
                            @extend %absolute;
                            @extend %mt-9-neg;
                        }
                        .team-score {
                            flex-direction: row;
                        }
                    }
                    &-name {
                        @extend %d-block;
                    }
                    &-img {
                        top: var(--space-2-neg);
                        @include square(var(--_logo-size));
                        @extend %relative;
                        &::after {
                            @extend %d-none;
                        }
                    }
                    &-score {
                        flex-direction: row-reverse;
                        gap: var(--space-1);
                        @extend %flex-n-c;
                        .score {
                            font-size: 1.4rem;
                        }
                    }
                    &-wrap {
                        gap: var(--space-1);
                        @extend %mt-4;
                    }
                    &-divider {
                        align-self: center;
                        @extend %mt-4-neg;
                    }
                }
                .fullname {
                    @extend %d-none;
                }
            }
            &-item {
                &.upcoming {
                    .card-body {
                        @extend %pb-0;
                        .text-vs {
                            @extend %d-none;
                        }
                        .team {
                            &-a,
                            &-b {
                                .shortname {
                                    @extend %mt-7-neg;
                                }
                            }
                        }
                    }
                    .countdown {
                        &-count {
                            @extend %font-16;
                        }
                        &-label {
                            @extend %font-10;
                        }
                        &-wrapper {
                            position: unset;
                        }
                        &-item {
                            min-width: 5rem;
                        }
                    }
                }
                &.super-over {
                    .card-body {
                        .team {
                            &-a,
                            &-b {
                                .shortname {
                                    @extend %mt-7-neg;
                                }
                            }
                            &-wrap {
                                gap: var(--space-3)
                            }
                        }
                    }
                    .team-data-wrap {
                        @extend %d-none;
                    }
                }
            }
        }
        .secondary-tabs {
            z-index: var(--z-sticky-tab);
            @include position(calc(var(--header-height) + var(--sticky-score-board-height)), null, null, 0, fixed);
            @include transition(500ms ease-in-out);
            @extend %w-100;
            @extend %neutral-50-bg;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        --container-max-width: 68%;
        --_logo-seperator: -2rem;
        --_text-score: 3.5rem;
        --_text-extra: 1.4rem;
        --_versus-size: 3rem;
        --_versus-font: 1.4rem;
        --_logo-size: 12rem;
        &.live {
            .card-wrap,
            .detail-info-container {
                margin: 0 auto 0 0;
            }
        }
        .card-section {
            padding-top: var(--header-height);
        }
        .card-wrap,
        .detail-info-container {
            max-width: max(var(--container-max-width));
            margin: 0 auto;
        }
        .footer-wrap {
            padding-inline: var(--space-4);
        }
        .card-head {
            .match-number {
                .text,
                .number {
                    font-size: 1.4rem;
                }
            }
            .match-info {
                .title {
                    font-size: 1.4rem;
                }
            }
            .match-status {
                margin-top: var(--space-1);
            }
        }
        .card-body {
            .team-wrap {
                gap: var(--space-9);
            }
            .team {
                gap: var(--space-12);
            }
            .team-extra {
                --_text-extra: 1rem;
            }
            .team-score {
                .score {
                    --_text-score: 2.2rem;
                }
                &:first-child {
                    .score {
                        --_text-score: 2.8rem;
                    }
                }
            }
        }
        .upcoming {
            .card-wrap {
                .card-body {
                    padding-bottom: 0;
                    .team-divider .text-vs {
                        display: none;
                    }
                }
            }
            .countdown-wrapper {
                position: unset;
                display: flex;
                flex-direction: column;
                gap: var(--space-1);
            }
            .countdown-count {
                font-size: 1.6rem;
            }
            .countdown-label {
                font-size: 1rem;
            }
        }
        .live-match-footer {
            .innings-info {
                flex-wrap: nowrap;
            }
        }
    }
    .sticky-score-card {
        .waf-cricketscorecard {
            --_z-score-card: 1;
            --_logo-size: 5rem;
            &.live {
                .tab-section .primary-tabs,
                .card-item.live .card-body,
                .card-item.upcoming .card-body,
                .card-item.super-over .card-body,
                .secondary-tabs {
                    max-width: max(var(--container-max-width));
                    margin: 0 auto;
                }
            }
            .tab-section .primary-tabs {
                border-radius: 0;
                @include transition(500ms ease-in-out);
                @include position(calc(var(--header-height) + var(--sticky-score-board-height)), unset, unset, null, fixed);
                .tab-name.tab-active {
                    border-radius: 0;
                }
            }
            .card {
                &-body {
                    @include background(var(--secondary-900), "matchcenter/score-sticky-bg.png", 100% 100%/cover no-repeat);
                    .team {
                        .name {
                            font-size: 1.6rem;
                        }
                        &.team-won {
                            .team-img {
                                border-color: clr(primary-500);
                            }
                        }
                        &-a {
                            .fullname {
                                right: calc(var(--_logo-size) + .6rem);
                                position: absolute;
                                margin-top: var(--space-12-neg);
                            }
                        }
                        &-b {
                            .fullname {
                                left: calc(var(--_logo-size) + .6rem);
                                position: absolute;
                                margin-top: var(--space-12-neg);
                            }
                        }
                        &-img {
                            padding: var(--space-1);
                            border: .1rem solid clr(secondary-50, 4);
                            border-radius: 50%;
                        }
                        &-score {
                            .score {
                                font-size: 1.6rem;
                            }
                        }
                        &-wrap {
                            gap: var(--space-8);
                        }
                        &-divider {
                            .text-vs {
                                font-size: 1.2rem;
                                @include square(2rem);
                            }
                        }
                    }
                    .fullname {
                        display: block;
                        white-space: nowrap;
                    }
                    .shortname {
                        display: none;
                    }
                }
                &-item {
                    &.upcoming {
                        .card {
                            &-body {
                                .team {
                                    .fullname {
                                        white-space: nowrap;
                                        margin-top: var(--space-10-neg);
                                    }
                                }
                            }
                        }
                        .countdown {
                            &-wrapper {
                                flex-direction: row;
                            }
                        }
                    }
                    &.super-over {
                        .card {
                            &-body {
                                .team {
                                    .fullname {
                                        white-space: nowrap;
                                        margin-top: var(--space-10-neg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .secondary-tabs {
                top: calc(var(--header-height) + var(--sticky-score-board-height) + 4.2rem);
                z-index: var(--z-sticky-tab);
            }
        }
    }
}