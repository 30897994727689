@use "../config" as *;
%icon {
    content: "";
    font: 1.4rem $font-icon;
    right: var(--space-3);
    @include square(1.8rem);
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %neutral-50;
}
%player-in {
    @include icon(in-out, 12);
    @extend %success-500-bg;
}
%player-out {
    @include icon(in-out, 12);
    @extend %error-500-bg;
}
.waf-cricketscorecard {
    .scorecard {
        @extend %px-3;
        .team-1105 {
            .team-score-detail {
                @extend %team-rc-bg;
            }
        }
        .team-1106 {
            .team-score-detail {
                @extend %secondary-900-bg;
            }
        }
        .team-1107 {
            .team-score-detail {
                @extend %team-pk-bg;
            }
        }
        .team-1108 {
            .team-score-detail {
                @extend %team-csk-bg;
            }
        }
        .team-1109 {
            .team-score-detail {
                @extend %team-dc-bg;
            }
        }
        .team-1110 {
            .team-score-detail {
                @extend %team-rr-bg;
            }
        }
        .team-1111 {
            .team-score-detail {
                @extend %team-mi-bg;
            }
        }
        .team-1379 {
            .team-score-detail {
                @extend %team-sr-bg;
            }
        }
        .team-2954 {
            .team-score-detail {
                @extend %team-lsg-bg;
            }
        }
        .team-2955 {
            .team-score-detail {
                @extend %team-gt-bg;
            }
        }
        .tab-head {
            flex-wrap: wrap;
            gap: var(--space-4);
            @extend %flex;
            .impact-player-legends {
                @extend %flex-sb-c;
                @extend %w-100;
                @extend %secondary-900-bg;
                @extend %p-2-3;
                @extend %half-radius;
            }
            .waf-select-box {
                min-width: 28rem;
            }
            .impact-player-label {
                gap: var(--space-2);
                @extend %flex-n-c;
            }
            .label {
                @extend %neutral-50;
                @extend %font-14-pb;
            }
            .label-txt {
                gap: .8rem;
                @extend %capitalize;
                @extend %neutral-50;
                @extend %font-13-pb;
                @extend %flex;
            }
            .in::after,
            .out::after {
                @extend %icon;
            }
            .in::after {
                @extend %player-in;
            }
            .out::after {
                @extend %player-out;
            }
        }
        .head-data {
            @extend %mb-3;
            .team {
                &-score-detail {
                    @extend %half-radius;
                    @extend %flex-sb-c;
                    @extend %p-2-3;
                }
                &-score-info {
                    @extend %flex-n-c;
                }
                &-name {
                    @extend %font-16-pb;
                    @extend %neutral-50;
                }
                &-logo {
                    width: 3rem;
                    aspect-ratio: 1;
                    @extend %mr-2;
                    filter: drop-shadow(0 0 0.2rem var(--neutral-50));
                }
            }
            .text {
                @extend %neutral-50;
                @extend %font-14-pr;
            }
            .fullname,
            .team-role {
                @extend %d-none;
            }
            .runs,
            .over-runrate {
                @extend %font-14-pb;
            }
        }
        .table-responsive {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            .table-head .text {
                @extend %font-14-pb;
                @extend %neutral-50;
            }
            .table-data {
                @extend %p-2;
            }
        }
        .table {
            margin: 0;
            width: 100%;
            border-radius: 0;
            border: none;
            display: table;
            &-head {
                height: 4rem;
                @extend %flex-n-c;
                @extend %secondary-900-bg;
            }
            &-row {
                height: max-content;
                @extend %flex-sb-s;
                @extend %w-100;
            }
            &-data {
                width: 8%;
                flex-shrink: 0;
                min-height: 3.2rem;
                @extend %text-center;
                @extend %flex-column-c-c;
                &.label {
                    width: 56%;
                    @extend %text-left;
                    @extend %px-2;
                    @extend %flex-column-c-fs;
                    .name {
                        @extend %m-0;
                    }
                }
            }
            &-body {
                .table-row {
                    border-bottom: .1rem solid clr(neutral-100, 4)
                }
                .table-row:nth-child(even) .table-data {
                    @extend %transparent-bg;
                }
                .table-row:nth-child(even) {
                    @extend %transparent-bg;
                }
            }
            .strike-rate,
            .economy-ball {
                width: 12%;
            }
            .name {
                @extend %font-14-pb;
            }
            .info {
                .text {
                    text-transform: none;
                }
            }
            .info .text {
                @extend %d-block;
                @extend %font-12-pr;
            }
            .player-thumbnail {
                @extend %d-none;
            }
        }
        .batter {
            .info {
                @extend %mt-2;
            }
            .table-responsive {
                .table-head .label {
                    &::before {
                        @include icon(all-rounder, 18);
                    }
                }
            }
        }
        .wickets {
            .table-head .label {
                &::before {
                    @include icon(wicket, 18);
                }
            }
            .name {
                @extend %pl-3;
            }
            .player-data {
                @include flex-config(flex, row-reverse);
            }
            .table-body {
                .table-data {
                    height: 5rem;
                }
            }
            .table-data {
                width: 20%;
                &.label {
                    width: 80%;
                }
            }
        }
        .bowler {
            .table-responsive {
                .table-head .label {
                    &::before {
                        @include icon(bowler, 20);
                    }
                }
                .table-data {
                    @extend %px-0;
                }
            }
            .table {
                &-data {
                    width: 8%;
                    &.label {
                        width: 41%;
                        @extend %px-2;
                    }
                }
            }
            .economy-rate {
                width: 11%;
            }
        }
        .extras-scorecard {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %secondary-900-bg;
            @extend %p-2;
            @extend %mb-3;
            span {
                @extend %font-12-pr;
                @extend %neutral-50;
            }
        }
        .player-in,
        .player-out {
            .table-data-wrapper {
                @extend %flex;
                &:after {
                    flex-shrink: 0;
                    @extend %ml-2;
                    @extend %icon;
                    @extend %player-in;
                }
            }
        }
        .player-out {
            .table-data-wrapper {
                &:after {
                    @extend %player-out;
                }
            }
        }
        .tab-container-wrap {
            @extend %p-0;
            .table-responsive {
                .table-head .label {
                    flex-direction: row;
                    @extend %flex-fs-c;
                    &::before {
                        @extend %neutral-50;
                        @extend %mr-1;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        .scorecard {
            .head-data {
                .team {
                    &-logo {
                        width: 5rem;
                    }
                }
                .shortname {
                    display: none;
                }
                .fullname {
                    display: block;
                }
            }
            .tab-head {
                gap: 0;
                .impact-player-legends {
                    margin-block: var(--space-4) 0;
                }
            }
            .table {
                &-body {
                    .table-data {
                        min-height: 8rem;
                    }
                }
                &-data {
                    width: 7%;
                    &.label {
                        width: 65%;
                    }
                    &.strike-rate,
                    &.economy-ball {
                        width: 7%;
                    }
                    .text {
                        font-size: 1.4rem;
                    }
                }
            }
            .wickets {
                .table-data {
                    width: 10%;
                    &.label {
                        width: 90%;
                    }
                    &.overs {
                        padding-right: var(--space-3);
                        @include flex-config(null, null, center, flex-end);
                    }
                }
            }
        }
    }
}