@use "../config/" as *;
.live-blog {
    .poll-section {
        @extend %d-none;
    }
    .article {
        &-thumbnail,
        &-description {
            @extend %mb-3;
        }
        &-item {
            @extend %mb-6;
            &.item-icon {
                .title-link {
                    @extend %pl-6;
                }
            }
        }
        &-title {
            @extend %font-16-pm;
            @extend %secondary-1000;
            @extend %mb-3;
        }
    }
    .item-type-icon {
        height: 2rem;
        width: 2rem;
        @extend %absolute;
        @extend %primary-500-bg;
        @extend %half-radius;
    }
    .item-type-icon:before {
        content: "\e882";
        font: 1.2rem/1 "waf-font-icon";
    }
    .title-link {
        @extend %d-inline-block;
    }
    .embed-responsive {
        @extend %half-radius;
    }
    .si-comm-des {
        @extend %flex-c-c;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-cricketscorecard.live {
        --_live-blog-width: calc(100% - var(--container-max-width));
        --_live-blog-header: 5rem;
        .live-blog {
            position: fixed;
            top: var(--header-height);
            right: 0;
            width: var(--_live-blog-width);
            height: calc(var(--window-inner-height) - var(--_live-blog-header));
            overflow: auto;
            padding-block: var(--_live-blog-header) var(--space-4);
            background-color: clr(secondary-50);
            &::before {
                content: "LIVE BLOG";
                height: var(--_live-blog-header);
                width: var(--_live-blog-width);
                top: var(--header-height);
                right: 0;
                position: fixed;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: clr(neutral-50);
                z-index: var(--z-blog-title);
                box-shadow: 0 0.4rem 1rem 0 clr(neutral-1000, 2);
                font-size: 1.4rem;
                font-weight: 700;
                color: clr(secondary-1000);
            }
            &::after {
                content: "\e880";
                font: 1.2rem/1 "waf-font-icon";
                font-weight: 700;
                position: fixed;
                top: 8rem;
                right: 20%;
                z-index: var(--z-blog-icon);
                transform: translateX(-20%);
            }
            .no-data {
                background: unset;
            }
        }
    }
}