@use "./config" as *;
.waf-cricketscorecard {
    .spider-section {
        @extend %relative;
        @extend %w-100;
        .svg-section-container {
            margin: var(--space-8) auto 0;
            @include square(30rem);
            @extend %relative;
            @extend %flex-c-n;
        }
        .svg-graph {
            @include background(var(--neutral-50), "matchcenter/spider-graph.svg", center / cover no-repeat);
            @include square(25rem);
            @extend %relative;
            @extend %mx-auto;
        }
        .svg-section {
            @include square(100%);
            @extend %flex-c-n;
        }
        .zone-listing {
            @include square(100%);
            @extend %absolute;
            .zone-item {
                @extend %absolute;
            }
            .item-one {
                top: -1.3rem;
                left: 7.5rem;
            }
            .item-two {
                top: -1.3rem;
                left: 21.5rem;
            }
            .item-three {
                top: 6rem;
                left: 28rem;
            }
            .item-four {
                top: 16rem;
                left: 28rem;
            }
            .item-five {
                top: 25rem;
                left: 20rem;
            }
            .item-six {
                top: 25rem;
                left: 9rem;
            }
            .item-seven {
                top: 17rem;
                left: 1rem;
            }
            .item-eight {
                top: 6rem;
                left: 0.5rem;
            }
        }
        .svg-div {
            top: 57%;
            @include position-combo(center);
            @include square(20rem);
            @extend %hidden;
            @extend %circle-radius;
        }
        .controls-list {
            gap: var(--space-2);
            flex-wrap: wrap;
            @extend %flex-c-c;
            @extend %m-auto;
            @extend %py-4;
        }
        .controls-item {
            flex-shrink: 0;
            border: 0.2rem solid clr(error-500);
            @extend %half-radius;
            @extend %flex-sb-s;
            .number,
            .runs {
                @include square(2.8rem);
                @extend %font-12-pb;
                @extend %flex-c-c;
            }
            .number {
                border: 0 var(--half-radius) var(--half-radius) 0;
                @extend %neutral-50;
            }
            &.item1 {
                border: .2rem solid clr(spider-1s);
                .number {
                    @extend %spider-1s-bg;
                }
            }
            &.item2 {
                border: .2rem solid clr(spider-2s);
                .number {
                    @extend %spider-2s-bg;
                }
            }
            &.item3 {
                border: .2rem solid clr(spider-3s);
                .number {
                    @extend %spider-3s-bg;
                }
            }
            &.item4 {
                border: .2rem solid clr(spider-4s);
                .number {
                    @extend %spider-4s-bg;
                }
            }
            &.item5 {
                border: .2rem solid clr(spider-5s);
                .number {
                    @extend %spider-5s-bg;
                }
            }
        }
    }
    .graphs-tab {
        border-bottom: 0.1rem solid clr(neutral-1000, 1);
        .tabs {
            .team-img {
                @extend %d-none;
            }
            .tab-name {
                gap: .5rem;
                @extend %flex-c-c;
                .score,
                .overs,
                .name {
                    @extend %font-14-pm;
                    @extend %neutral-1000;
                }
                .fullname {
                    @extend %d-none;
                }
                .shortname {
                    @extend %d-block;
                }
                &:not(:last-child) {
                    @include separator(50%, y, 0.2, secondary-1000);
                }
                &.tab-active {
                    border-bottom: 0.2rem solid clr(secondary-1000);
                    .name {
                        font-weight: 700;
                    }
                    .score,
                    .overs,
                    .name {
                        @extend %secondary-1000;
                    }
                }
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        .spider-section {
            position: relative;
            .controls-list {
                @include flex-config(null, column, null, null);
                @include position(6%, calc((var(--container-max-width) - 30rem) / 2), null, null);
            }
            .controls-item {
                .runs {
                    width: 4rem;
                }
            }
        }
        .graphs-tab {
            .tabs {
                overflow-x: unset;
            }
        }
    }
}