@use "../config" as *;
%icon {
    content: "";
    font: 1.4rem $font-icon;
    right: var(--space-3);
    @include square(1.8rem);
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %neutral-50;
}
%player-in {
    @include icon(in-out, 12);
    @extend %success-500-bg;
}
%player-out {
    @include icon(in-out, 12);
    @extend %error-500-bg;
}
.team-impact-player {
    &-body {
        @extend %half-radius;
        @extend %secondary-1000-bg;
        @extend %p-3;
        @extend %mb-3;
        .player-name {
            @extend %neutral-50;
            @extend %font-14-pb;
            @extend %capitalize;
            @extend %flex;
            &:not(:last-child) {
                @extend %mb-2;
            }
            &::before {
                @extend %icon;
                @extend %mr-2;
            }
            &.player-in::before {
                @extend %player-in;
            }
            &.player-out::before {
                @extend %player-out;
            }
        }
        .player-replace {
            right: 0;
            width: 3rem;
            height: 3rem;
            @include square(3rem);
            @extend %pos-y-center;
            @extend %secondary-900-bg-5;
            @extend %circle-radius;
            &::after {
                width: 100%;
                height: 100%;
                @include icon(replace, 17);
                @extend %flex-c-c;
                @extend %neutral-50;
            }
        }
    }
    &-item {
        position: relative;
        @extend %flex-column;
    }
}
.impact-player-header {
    @extend %mb-3;
}
.impact-player-container {
    .title {
        @extend %mb-3;
    }
    .title,
    .impact-player-name {
        @extend %font-16-pb;
        @extend %neutral-1000;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .team-impact-player {
        &-body {
            .player-replace {
                position: unset;
                translate: unset;
                margin-right: var(--space-3);
            }
            .player-name {
                font-size: 1.6rem;
                &:first-child {
                    margin-bottom: 0;
                    margin-right: var(--space-3);
                }
            }
        }
        &-item {
            @include flex-config(null, row, null, center);
        }
    }
}