@use '../config/' as *;
.matchinfo {
    .matchinfo-item {
        min-height: 5.4rem;
        border-bottom: .1rem solid clr(neutral-1000, 1);
        @include separator(100%);
        @extend %px-3;
        @extend %gap-10;
        @extend %flex-sb-c;
    }
    .label {
        @extend %font-12-pr;
    }
    .info,
    .item-info {
        @extend %text-right;
        @extend %font-12-pb;
    }
    .item-info {
        .info-wrap {
            @extend %flex-column;
            .item-name {
                @extend %font-10-pr;
            }
        }
    }
    .venue .info {
        @extend %font-14;
        &:before {
            content: '\e87e';
            font: 1.2rem/1 "waf-font-icon";
            @extend %mr-1;
        }
    }
    .date .info::before {
        content: '\e851';
        font: 1.5rem/1 "waf-font-icon";
        font-weight: 700;
        @extend %mr-1;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .matchinfo-item {
        .label {
            width: 30%;
            font-size: 1.4rem
        }
        .info,
        .item-info {
            width: 70%;
            text-align: left;
            font-size: 1.4rem;
        }
        .item-info {
            .info-wrap {
                .item-name {
                    font-size: 1.4rem;
                }
            }
        }
    }
}